<template>
  <div id="base-container">
    <navbar></navbar>
    <div id="app">
      <router-view></router-view>
      <cookie-law information-button-link="/impress" close-button-variant="success" information-button-text="Mehr Informationen" close-button-text="Okay!" theme="gray-transparent">
        <p slot="message">Diese Website benutzt Cookies. Durch die Nutzung des Angebots akzeptieren Sie unsere Datenschutzbedingungen.</p>
      </cookie-law>
    </div>
    <noscript><p><img src="https://piwik.giz.berlin/piwik.php?idsite=10&amp;rec=1" style="border:0;" alt="" /></p></noscript>
  </div>
</template>

<script>
import CookieLaw from 'vue-bootstrap-cookie-law'

import Navbar from './components/Navbar.vue'
export default {
  name: 'app',
  components: {
    navbar: Navbar,
    'cookie-law': CookieLaw
  }
}
</script>

<style lang="scss">
  @import '~bootstrap/scss/bootstrap';
  @import '~animate.css/animate.css';

  #app {
    overflow: hidden;
  }

  h2 {
    margin-top: 10px;
  }
</style>
