<template>
  <div class="container">
    <h2>Oh no!</h2>
    <p>Something went wrong :(</p>
    <p>Please restart the application</p>
  </div>
</template>

<script>
export default {
  name: 'error'
}
</script>

<style scoped lang="scss">
  @import '../style/bootstrap-component-include';
</style>
