<template>
  <div class="place-info">
    <div v-bind:class="{ fadeIn: !loading, animated: true, fadeOut: loading }">
      <div class="content" v-if="!loading">
        <div id="activeMarkerInfoBoxClose" v-on:click="closeInfoBox()" :class="{ reading: currentReadItem === 'closeOverview' }">
          <icon id="closeOverview" name="times" :scale="2" class="image-overlay-opacity"></icon>
        </div>
        <b-tooltip target="activeMarkerInfoBoxClose" title="Schließen" placement="bottom"></b-tooltip>

        <div class="image-wheel-container" v-bind:class="{ full: imageWheelFullScreen }" v-if="placeData['images'].length > 0">
          <div class="image-wheel">
            <img v-for="image in placeData['images']" :key="buildImageUrl(image)" :src="buildImageUrl(image)" v-on:click="toggleImageWheelFullScreen()" />
          </div>
        </div>

        <div id="place-title">
          <h2 class="place-info-content">
            <b-badge variant="primary" id="classification-symbol-read" class="classification-symbol-container read-out" v-on:click="readOutPlace()">
              <icon id="readIcon" name="volume-up" scale="1.4" class="classification-symbol"></icon>
              <b-tooltip target="classification-symbol-read" title="Vorlesen" placement="bottom"></b-tooltip>
            </b-badge>
            <span :class="{ reading: currentReadItem === 'title', readable: true }">{{ placeData['title'] }}</span>&nbsp;
          </h2>
          <h2 class="place-info-content">
            <b-badge v-if="placeData['offersConsulting']" id="classification-symbol-container-consulting" :class="{ reading: currentReadItem === 'offersConsulting', readable: true, 'classification-symbol-container': true }">
              <icon id="offersConsulting" name="users" scale="1.4" class="classification-symbol"></icon>
              <b-tooltip target="classification-symbol-container-consulting" title="Beratung" placement="bottom"></b-tooltip>
            </b-badge>
            <b-badge v-if="placeData['offersABC']" id="classification-symbol-container-abc" :class="{ reading: currentReadItem === 'offersABC', readable: true, 'classification-symbol-container': true }">
              <icon id="offersABC" name="pencil-alt" scale="1.4" class="classification-symbol"></icon>
              <b-tooltip target="classification-symbol-container-abc" title="Lesen, Schreiben und Rechnen" placement="bottom"></b-tooltip>
            </b-badge>
            <b-badge v-if="placeData['offersMedia']" id="classification-symbol-container-media" :class="{ reading: currentReadItem === 'offersMedia', readable: true, 'classification-symbol-container': true }">
              <icon id="offersMedia" name="laptop" scale="1.4" class="classification-symbol"></icon>
              <b-tooltip target="classification-symbol-container-media" title="Medien" placement="bottom"></b-tooltip>
            </b-badge>
            <b-badge v-if="placeData['offersOther']" id="classification-symbol-container-other" :class="{ reading: currentReadItem === 'offersOther', readable: true, 'classification-symbol-container': true }">
              <icon id="offersOther" name="sign-language" scale="1.4" class="classification-symbol"></icon>
              <b-tooltip target="classification-symbol-container-other" title="andere Angebote" placement="bottom"></b-tooltip>
            </b-badge>
          </h2>
        </div>

        <p :class="{ reading: currentReadItem === 'shortDescription', 'place-info-content': true, readable: true }">
          {{ placeData['shortDescription'] }}
        </p>

        <div id="more-content" v-if="placeData['description']">
          <div class="text-center">
            <b-btn v-b-modal.place-more-info variant="outline-success" size="sm" :class="{ reading: currentReadItem === 'more' }">
              <icon name="info" class="symbol"></icon>&nbsp;&nbsp;Mehr Informationen anzeigen
            </b-btn>
          </div>
        </div>

        <a :href="'https://maps.google.de/maps?q=' + placeData['latitude'] + ',' + placeData['longitude']" :class="{ reading: currentReadItem === 'route', readable: true, 'place-info-widget': true }">
          <icon name="map-signs" :scale="moreInformationIconScale" class="fa"></icon>
          <p>Route anzeigen</p>
        </a>
        <a v-if="placeData['telephoneNumber']" :href="'tel:' + placeData['telephoneNumber']"  :class="{ reading: currentReadItem === 'call', readable: true, 'place-info-widget': true }">
          <icon name="phone" :scale="moreInformationIconScale" class="fa"></icon>
          <p>Anrufen</p>
          <p class="additional-text">{{ placeData['telephoneNumber'] }}</p>
        </a>
        <a v-if="placeData['emailAddress']" :href="'mailto:' + placeData['emailAddress']"  :class="{ reading: currentReadItem === 'email', readable: true, 'place-info-widget': true }">
          <icon name="envelope" :scale="moreInformationIconScale * 0.75" class="fa"></icon>
          <p>E-Mail</p>
          <p class="additional-text" style="word-break: break-all;">{{ placeData['emailAddress'] }}</p>
        </a>
        <a href="#" v-on:click="$refs.openingHours.show()" v-if="hasOpeningHours" :class="{ reading: currentReadItem === 'openingHoursSimple', readable: true, 'place-info-widget': true }">
          <icon name="regular/clock" :scale="moreInformationIconScale" class="fa"></icon>
          <div v-if="placeData['openingHours'][this.weekdayString].length > 0">
            <p>Heute verfügbar</p>
            <p class="additional-text">{{ getTodayOpenTime() }} - {{ getTodayClosingTime() }} Uhr</p>
          </div>
          <p v-else>
            Öffnungszeiten anzeigen
          </p>
        </a>
        <a v-if="placeData['website']" :href="placeData['website']" :class="{ reading: currentReadItem === 'website', readable: true, 'place-info-widget': true }">
          <icon name="globe" :scale="moreInformationIconScale" class="fa"></icon>
          <p>Website besuchen</p>
        </a>
        <a href="http://grundbildung-berlin.de/alpha-siegel/" target="_blank" v-if="placeData['alphaSiegel']" :class="{ reading: currentReadItem === 'alphaSiegel', readable: true, 'place-info-widget': true, 'alpha-siegel-widget-image': true }">
          <img src="../assets/images/alpha-siegel.png">
        </a>
      </div>
    </div>
    <div class="d-fixed" id="modify-content">
      <b-button size="sm" :to="'/map/placeEdit/' + this.marker + '/' + placeData['revision']">Ort bearbeiten</b-button>
    </div>

    <b-modal title="Öffnungszeiten" ref="openingHours" ok-only v-if="!loading">
      <div v-for="openingDay in openingHoursDays" :key="openingDay.de">
        <div class="openingHour" v-if="placeData['openingHours'][openingDay.en].length > 0">
          <h6>{{ openingDay.de }}</h6>
          <ul>
            <div v-for="openingEvent in placeData['openingHours'][openingDay.en]" :key="openingEvent['open'] + openingEvent['close']">
              <li>{{ openingEvent['open'] }} Uhr &mdash; {{ openingEvent['close'] }} Uhr</li>
            </div>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal id="place-more-info" ok-only title="Mehr Informationen">
      <b-badge variant="primary" id="classification-symbol-read-more" class="read-out" v-on:click="readOutMore()">
        <icon id="readMoreIcon" name="volume-up"></icon>
        <b-tooltip target="readMoreIcon" title="Vorlesen" placement="bottom"></b-tooltip>
      </b-badge>
      {{ placeData['description'] }}
    </b-modal>

  </div>
</template>

<script>
import Raven from 'raven-js'

import { BACKEND_BASE_URL } from '../constants'

const weekday = new Array(7)
weekday[0] = 'Sunday'
weekday[1] = 'Monday'
weekday[2] = 'Tuesday'
weekday[3] = 'Wednesday'
weekday[4] = 'Thursday'
weekday[5] = 'Friday'
weekday[6] = 'Saturday'

export default {
  name: 'Place',
  props: [
    'marker'
  ],
  methods: {
    toggleImageWheelFullScreen () {
      this.imageWheelFullScreen = !this.imageWheelFullScreen
    },
    closeInfoBox () {
      this.$emit('close')
    },
    loadPlaceData () {
      this.$http.get(BACKEND_BASE_URL + '/v1/map/point/' + this.marker).then((response) => {
        this.placeData = response.body

        this.hasOpeningHours = false
        for (const weekdayIdx in this.placeData.openingHours) {
          if (this.placeData.openingHours[weekdayIdx].length > 0) {
            this.hasOpeningHours = true
          }
        }

        this.loading = false
      }, (response) => {
        if (response.status !== 404) {
          Raven.captureMessage('Place loading error ' + response.status, {
            extra: { response: response }
          })
        }
        this.$router.push('/error')
      })
    },
    buildImageUrl (image) {
      return BACKEND_BASE_URL + '/v1/map/point/image/' + image + '/thumbnail'
    },
    getTodayOpenTime () {
      return this.placeData.openingHours[this.weekdayString][0].open
    },
    getTodayClosingTime () {
      return this.placeData.openingHours[this.weekdayString][0].close
    },
    readOutPlace () {
      if (this.currentReadItem !== null) {
        return
      }

      function readListItem (audioObjectList, component) {
        const audio = audioObjectList.pop()
        component.currentReadItem = audio.id

        audio.object.onended = () => {
          component.currentReadItem = null
          if (audioObjectList.length > 0) {
            readListItem(audioObjectList, component)
          }
        }

        audio.object.onerror = () => {
          component.currentReadItem = null
        }

        audio.object.play()
      }

      const readItems = []

      readItems.push({
        id: 'title'
      })

      if (this.placeData.offersOther || this.placeData.offersABC || this.placeData.offersConsulting || this.placeData.offersMedia) {
        readItems.push({
          id: 'offersIntroduce'
        })
        if (this.placeData.offersConsulting) {
          readItems.push({
            id: 'offersConsulting'
          })
        }
        if (this.placeData.offersABC) {
          readItems.push({
            id: 'offersABC'
          })
        }
        if (this.placeData.offersMedia) {
          readItems.push({
            id: 'offersMedia'
          })
        }
        if (this.placeData.offersOther) {
          readItems.push({
            id: 'offersOther'
          })
        }
      }

      readItems.push({
        id: 'shortDescription'
      })

      if (this.placeData.description) {
        readItems.push({
          id: 'more'
        })
      }

      readItems.push({
        id: 'route'
      })

      if (this.placeData.telephoneNumber) {
        readItems.push({
          id: 'call'
        })
      }

      if (this.placeData.emailAddress) {
        readItems.push({
          id: 'email'
        })
      }

      if (this.hasOpeningHours) {
        readItems.push({
          id: 'openingHoursSimple'
        })
      }

      if (this.placeData.website) {
        readItems.push({
          id: 'website'
        })
      }

      if (this.placeData.alphaSiegel) {
        readItems.push({
          id: 'alphaSiegel'
        })
      }

      readItems.push({
        id: 'closeOverview'
      })

      const audioObjectList = []

      for (const readItemIdx in readItems) {
        const audioURL = BACKEND_BASE_URL + '/v1/map/point/' + this.marker + '/revision/' + this.placeData.revision +
            '/voice/de/' + readItems[readItemIdx].id
        audioObjectList.push({ id: readItems[readItemIdx].id, object: new Audio(audioURL) })
      }

      audioObjectList.reverse()

      readListItem(audioObjectList, this)
    },
    readOutMore () {
      const audioURL = BACKEND_BASE_URL + '/v1/map/point/' + this.marker + '/revision/' + this.placeData.revision +
          '/voice/de/description'

      const audio = new Audio(audioURL)

      audio.play()
    }
  },
  data () {
    return {
      imageWheelFullScreen: false,

      loading: true,
      placeData: {},
      hasOpeningHours: false,

      weekdayString: weekday[new Date().getDay()],

      currentReadItem: null,

      moreInformationIconScale: 3.3,

      openingHoursDays: [
        {
          de: 'Montag',
          en: 'Monday'
        },
        {
          de: 'Dienstag',
          en: 'Tuesday'
        },
        {
          de: 'Mittwoch',
          en: 'Wednesday'
        },
        {
          de: 'Donnerstag',
          en: 'Thursday'
        },
        {
          de: 'Freitag',
          en: 'Friday'
        },
        {
          de: 'Samstag',
          en: 'Saturday'
        },
        {
          de: 'Sonntag',
          en: 'Sunday'
        }
      ]
    }
  },
  created () {
    this.loadPlaceData()
  },
  watch: {
    marker () {
      this.loading = true
      this.loadPlaceData()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  .classification-symbol-container {
    height: 40px;
    margin: 6px;
    &.read-out {
      margin-left: 0;
    }
  }

  .image-overlay-opacity {
    opacity: 0.8;
  }

  #activeMarkerInfoBoxClose {
    position: absolute;
    right: 5px;
    top: 20px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    z-index: 2;
  }

  .place-info-widget {
    $place-info-widget-width: 170px;
    width: $place-info-widget-width;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: calc(25% - (#{$place-info-widget-width} / 2));
    margin-right: calc(25% - (#{$place-info-widget-width} / 2));
    margin-outside: 10%;
    border: 1px solid #222222;
    border-radius: 5px;
    height: 160px;
    float: left;

    text-align: center;
    padding-top: 25px;

    .fa {
      font-size: 365%;
      margin-bottom: 5px;
    }

    margin-bottom: 20px;
    display: block;

    color: $body-color;

    &:hover {
      text-decoration: none;
    }

    p {
      line-height: 120%;

      margin-bottom: 0;

      &.additional-text {
        color: #444444;
      }
    }

    &.readable {
      transition: color 0.2s ease-in-out;
    }
  }

  .image-wheel-container {

    width: calc(100% + 30px);
    overflow-x: scroll;
    margin-left: -15px;
    margin-right: -15px;

    &.full {
      position: fixed;
      left: 0;
      top: 0;

      z-index: 100;

      .image-wheel, img {
        height: 90vh;
      }

    }
  }

  .image-wheel {
    $image-height: 180px;

    height: $image-height;
    margin: 0;
    padding: 0;

    img, .wrapper {
      height: $image-height;
      width: auto;
      display: inline-block;
    }

    white-space: nowrap;
    display: block;
    overflow: visible;
    overflow-scrolling: touch;
  }

  #more-content {
    margin-bottom: 15px;

    .symbol {
      margin-top: -5px;
      vertical-align: middle;
    }
  }

  #modify-content {
    z-index: 10;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .alpha-siegel-widget-image {
    padding-top: 15px;

    img {
      width: 80%;
    }

    &.reading {
      background-color: $orange;
      transition: all 0.5s ease-in-out !important;
    }

    transition: all 2.4s ease-in-out !important;
  }

  .reading {
    color: $orange;
    transition: color 0.5s ease-in-out !important;
  }

  .readable {
    transition: color 2.4s ease-in-out;
  }

  .read-out {
    cursor: pointer;
  }

  .content {
    overflow-y: auto;
    height: calc(100vh - 56px);
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }

  .place-info-content {
    padding: 7px;
  }

  #place-title {
    padding: 5px;

    h2 {
      float: left;
    }

    &:after {
      content: ".";
      clear: both;
      display: block;
      visibility: hidden;
      height: 0px;
    }

    .place-info-content {
      padding: 2px;
    }
  }
</style>
