import Vue from 'vue'
import Router from 'vue-router'
import Map from '@/views/Map'
import Impress from '@/views/Impress'
import PlaceEdit from '@/views/PlaceEdit'
import Alphabuendnis from '@/views/Alphabuendnis'
import Contact from '@/views/Contact'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/buendnis'
    },
    {
      path: '/impress',
      name: 'Impress',
      component: Impress
    },
    {
      path: '/map',
      name: 'Map',
      component: Map
    },
    {
      path: '/map/place/:pid',
      name: 'MapPlace',
      component: Map
    },
    {
      path: '/map/placeEdit',
      name: 'PlaceNew',
      component: PlaceEdit
    },
    {
      path: '/map/placeEdit/:pid/:rid',
      name: 'PlaceEdit',
      component: PlaceEdit
    },
    {
      path: '/buendnis',
      name: 'AlphaBuendnis',
      component: Alphabuendnis
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '*',
      name: 'error',
      component: require('@/views/ErrorPage').default
    }
  ]
})
