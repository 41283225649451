
import BootstrapVue from 'bootstrap-vue'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import VueResource from 'vue-resource'
import { Icon as LIcon } from 'leaflet'

import Vue from 'vue'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import App from './App'
import router from './router'

if (process.env.NODE_ENV !== 'development') {
  Raven
    .config('https://6eedb93f64c34e0f996d09ed47c2a130@sentry.giz.berlin/2')
    .addPlugin(RavenVue, Vue)
    .install()

  // Matomo
  const _paq = []
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView'])
  _paq.push(['enableLinkTracking']);
  (function () {
    const u = 'https://piwik.giz.berlin/'
    _paq.push(['setTrackerUrl', u + 'piwik.php'])
    _paq.push(['setSiteId', '10'])
    const d = document; const g = d.createElement('script'); const s = d.getElementsByTagName('script')[0]
    g.type = 'text/javascript'; g.async = true; g.defer = true; g.src = u + 'piwik.js'; s.parentNode.insertBefore(g, s)
  })()
  // End Matomo Code
}

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.component('icon', Icon)
Vue.use(VueResource)

Vue.directive('set-class-on-scroll-vertical', {
  inViewport (el) {
    const rect = el.getBoundingClientRect()

    const offsetVertical = window.innerHeight / 4

    return (
      rect.top > offsetVertical &&
        rect.top < (window.innerHeight - offsetVertical)
    ) || (
      rect.bottom > offsetVertical &&
        rect.bottom < (window.innerHeight - offsetVertical)
    )
  },

  bind (el, binding) {
    el.classList.add('invisible')
    el.$onScroll = function () {
      if (binding.def.inViewport(el)) {
        el.classList.add(binding.value)
        el.classList.remove('invisible')
        binding.def.unbind(el, binding)
      }
    }
    document.addEventListener('scroll', el.$onScroll)
  },

  inserted (el) {
    el.$onScroll()
  },

  created (el) {
    el.$onScroll()
  },

  unbind (el) {
    document.removeEventListener('scroll', el.$onScroll)
    delete el.$onScroll
  }
})

delete LIcon.Default.prototype._getIconUrl
LIcon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
