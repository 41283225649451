<template>
  <div class="place-edit container">
    <h2>Kontaktformular</h2>
    <div>
      <form v-on:submit="submitForm()" onsubmit="event.preventDefault()">
        <h5>Ihre Kontaktdaten</h5>
        <b-form-group>
          <label for="input-name">Ihr Name</label>
          <input type="text" class="form-control" id="input-name" name="creatorName" v-model="nameSubmitter" placeholder="Martha Mustermann" required>
        </b-form-group>

        <b-form-group>
          <label for="input-email">Persönliche E-Mail Adresse</label>
          <input type="email" class="form-control" id="input-email" name="creatorMailAddress" v-model="emailAddressSubmitter" aria-describedby="emailHelp" placeholder="Mail-Adresse eingeben" required>
        </b-form-group>

        <b-form-group>
          <label for="input-tel">Telefon</label>
          <input type="text" class="form-control" id="input-tel" name="creatorTelNumber" v-model="telSubmitter" placeholder="+49 160 1234567">
        </b-form-group>

        <h5>Ihre Nachricht</h5>
        <b-form-group>
          <textarea class="form-control" id="input-message" name="message" v-model="message" rows="4" required></textarea>
        </b-form-group>

        <h5 v-on:click="$refs.finishModalShow.show()">Rechtliches</h5>
        <b-form-checkbox id="acceptLicense" required>
          Mit Absendung dieses Formulars akzeptieren Sie die im Impressum aufgeführten Datenschutzbedigungen.
        </b-form-checkbox>

        <br>

        <button type="submit" class="btn btn-primary mt-2">Absenden</button>
      </form>
    </div>
    <b-modal title="Danke!" ref="finishModalShow" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" ok-only :ok-disabled="!submitSuccessful" v-on:ok="successDismiss">
      <p><b>{{ actualSubmitStatus }}</b></p>
      <p v-if="!submitError">Falls gewünscht werden wir uns in den nächsten Tagen bei Ihnen melden.</p>
      <p v-else>Es ist leider etwas schief gelaufen. Prüfen Sie Ihre Internetverbindung, laden Sie diese Seite neu und probieren Sie es erneut. Das tut uns leid.</p>
    </b-modal>
  </div>
</template>

<script>
import { BACKEND_BASE_URL } from '../constants'

export default {
  name: 'Contact',
  methods: {
    submitForm () {
      const url = BACKEND_BASE_URL + '/v1/contact/alphabuendnis'

      this.$refs.finishModalShow.show()
      this.actualSubmitStatus = 'Senden...'

      let message = ''

      message += 'Name: ' + this.nameSubmitter + '\n'
      message += 'Mail Adresse: ' + this.emailAddressSubmitter + '\n'
      message += 'Telefon: ' + this.telSubmitter + '\n'
      message += '\nNachricht\n===================\n'
      message += this.message

      this.$http.post(url, {
        message: message
      }).then(response => {
        this.actualSubmitStatus = 'Erfolgreich!'
        this.submitSuccessful = true
      }).catch(error => {
        this.actualSubmitStatus = 'Oh nein!'
        this.submitSuccessful = false
        this.submitError = true

        throw error
      })

      return false
    },
    successDismiss () {
      this.$router.push('/')
    }
  },
  data () {
    return {
      actualSubmitStatus: 'UNKNOWN',
      submitSuccessful: false,
      submitError: false,
      message: '',
      telSubmitter: '',
      emailAddressSubmitter: '',
      nameSubmitter: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';
</style>
