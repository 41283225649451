<template>
  <div class="place-edit container">
    <h2>Ort editieren</h2>
    <div v-bind:class="{ fadeIn: !loading, animated: !loading, invisible: loading }">
      <b-alert v-if="browserDetect && browserDetect.name === 'ie'" show variant="danger">Sie nutzen einen veralteten Browser! Bitte verwenden Sie einen aktuellen Firefox oder Chrome, um Orte zu editieren.</b-alert>

      <form v-else v-on:submit="submitForm()" onsubmit="event.preventDefault()">
        <h5>Daten zum Einreichenden</h5>
        <b-form-group>
          <label for="input-email">Persönliche E-Mail Adresse</label>
          <input type="email" class="form-control" id="input-email" name="creatorMailAddress" v-model="emailAddressSubmitter" aria-describedby="emailHelp" placeholder="Mail-Adresse eingeben" required>
          <small id="emailHelp" class="form-text text-muted">Wir werden diese Mail-Adresse nicht veröffentlichen. Sie dient nur für etwaige Rückfragen.</small>
        </b-form-group>

        <h5>Daten über den Ort</h5>
        <b-form-group>
          <label for="input-title">Titel</label>
          <input type="text" class="form-control" id="input-title" name="title" v-model="title" aria-describedby="title-help" placeholder="z.B. Alphabetisierungskurse oder Trägername" required>
          <small id="title-help" class="form-text text-muted">Hier bitte einen aussagekräftigen, kurzen und prägnanten Titel angeben.</small>
        </b-form-group>

        <b-form-group>
          <label for="input-street">Straßenname</label>
          <input type="text" class="form-control" id="input-street" name="street" v-model="street" placeholder="Reformationsplatz" v-on:change="fetchAddress()" required>
        </b-form-group>

        <b-form-group>
          <label for="input-street">Hausnummer</label>
          <input type="text" class="form-control" id="input-houseNumber" name="houseNumber" v-model="houseNumber" placeholder="2" v-on:change="fetchAddress()" required>
        </b-form-group>

        <b-form-group>
          <label for="input-street">Postleitzahl</label>
          <input type="number" class="form-control" id="input-postCode" name="postCode" v-model="postCode" placeholder="13597" v-on:change="fetchAddress()" required>
        </b-form-group>

        <b-form-group>
          <label for="input-street">Stadt</label>
          <input type="text" class="form-control" id="input-city" name="city" v-model="city" placeholder="Berlin" v-on:change="fetchAddress()" required>
        </b-form-group>

        <v-map :zoom=16 :center="[latitude, longitude]" v-if="latitude != null && longitude != null" id="address-map">
          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" :attribution="mapAttribution"></v-tilelayer>
          <v-marker title="Nominatim lookup name" :lat-lng="[latitude, longitude]">
            <v-popup :content="nominatimResponse['display_name']"></v-popup>
          </v-marker>
        </v-map>
        <b-alert v-else show>Adresse nicht vollständig oder nicht auflösbar. Bitte kontrollieren, damit Punkt auf Karte angezeigt werden kann.</b-alert>

        <b-form-group>
          <b-form-checkbox id="offersConsulting" v-model="offersConsulting">
            Bietet Beratung an
          </b-form-checkbox>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox id="offersABC" v-model="offersABC">
            Bietet Alphabetisierung an
          </b-form-checkbox>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox id="offersMedia" v-model="offersMedia">
            Bietet Medienangebote an
          </b-form-checkbox>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox id="offersOther" v-model="offersOther">
            Bietet andere, nicht aufgeführte Angebote an
            <small class="form-text text-muted">Andere Angebote bitte unten spezifizieren.</small>
          </b-form-checkbox>
        </b-form-group>

        <b-form-group>
          <label for="input-shortDescription">Kurze Beschreibung</label>
          <textarea class="form-control" id="input-shortDescription" aria-describedby="shortDescription-help" name="shortDescription" v-model="shortDescription" rows="2" required></textarea>
          <small id="shortDescription-help" class="form-text text-muted">Kurze Beschreibung der Angebote, kein ausführlicher Text. Bitte einfache Sprache verwenden.</small>
        </b-form-group>

        <b-form-group>
          <label for="input-description">Längere Beschreibung</label>
          <textarea class="form-control" id="input-description" aria-describedby="description-help" name="description" v-model="description" rows="4"></textarea>
          <small id="description-help" class="form-text text-muted">Möglicherweise ausführliche Aufschlüsselung der Angebote, optional.</small>
        </b-form-group>

        <b-form-group>
          <label for="input-telephoneNumber">Telefonnummer</label>
          <input type="text" class="form-control" id="input-telephoneNumber" name="telephoneNumber" v-model="telephoneNumber" aria-describedby="telephoneNumber-help" placeholder="+49305130100">
          <small id="telephoneNumber-help" class="form-text text-muted">Telefonnummer bitte ohne Leerzeichen und im internationalen Stil angeben.</small>
        </b-form-group>

        <b-form-group>
          <label for="input-website">Website</label>
          <input type="text" class="form-control" id="input-website" name="website" v-model="website" aria-describedby="website-help" placeholder="https://giz.berlin/alphabuendnis">
          <small id="website-help" class="form-text text-muted">Bitte mit Unterseite angeben, kompletten Link.</small>
        </b-form-group>

        <b-form-group>
          <label for="input-email">E-Mail Adresse</label>
          <input type="email" class="form-control" id="input-emailOwner" name="ownerMailAddress" v-model="emailAddress" aria-describedby="emailHelp" placeholder="Mail-Adresse eingeben">
        </b-form-group>

        <b-form-checkbox if="alphaSiegel" v-model="alphaSiegel">
          Ist nach dem Alpha-Siegel zertifiziert
          <small class="form-text text-muted"><a href="http://grundbildung-berlin.de/alpha-siegel/" target="_blank">Mehr Informationen über das Alpha-Siegel.</a></small>
        </b-form-checkbox>

        <h5>Öffnungszeiten</h5>
        <div v-for="opening in openingHoursDays" :key="opening.de">
          <b-form-group>
            <label :for="'openingHours' + opening.en + 'Open'">Öffnung {{ opening.de }}</label>
            <input type="time" class="form-control" :id="'openingHours' + opening.en + 'Open'" :name="'openingHours' + opening.en + 'Open'" v-model="openingHours[opening.en]['open']">
          </b-form-group>
          <b-form-group>
            <label :for="'openingHours' + opening.en + 'Close'">Schließzeit {{ opening.de }}</label>
            <input type="time" class="form-control" :id="'openingHours' + opening.en + 'Close'" :name="'openingHours' + opening.en + 'Close'" v-model="openingHours[opening.en]['close']">
          </b-form-group>
        </div>

        <h5>Bilder</h5>
        <v-dropzone ref="imageDropzone" id="dropzone" :options="dropzoneOptions"></v-dropzone>

        <h5 v-on:click="$refs.finishModalShow.show()">Rechtliches</h5>
        <b-form-checkbox id="acceptLicense" required>
          Ich räume dem Alphabündnis Berlin-Spandau ein uneingeschränktes Nutzungsrecht der ortsbezogenen Daten ein. Das Bündnis darf des Weiteren die Daten dauerhaft unter der <a href="https://opendatacommons.org/licenses/odbl/1.0/">ODbL</a> anderen Nutzern zur Verfügung zu stellen. Ich stimme außerdem zu, dass eine Relizensierung und Modifikation meiner eingebenen Daten zulässig ist.
          <small class="form-text text-muted">Wir möchten die Daten sinnvoll nutzen. Deshalb behalten wir uns mit dieser Zustimmung beispielsweise vor, die Daten auch kommerziell zu verwenden, wenn es der Zielgruppe dienlich ist. Wir werden uns bemühen die Daten dauerhaft per API unter einer freien Lizenz zur Weiterverwendung zur Verfügung zu stellen.</small>
        </b-form-checkbox>

        <button type="submit" class="btn btn-primary">Absenden</button>
      </form>
    </div>
    <b-modal title="Danke!" ref="finishModalShow" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" ok-only :ok-disabled="!submitSuccessful" v-on:ok="successDismiss">
      <p><b>{{ actualSubmitStatus }}</b></p>
      <p>Nach einer Überprüfung werden wir Ihren Beitrag freischalten. Dies kann einige Tage dauern.</p>
    </b-modal>

    <b-modal title="Kein Punkt?" ref="addressErrorModalShow" ok-only>
      <p>Bitte überprüfen Sie die Adresse!</p>
    </b-modal>
  </div>
</template>

<script>
import { LTileLayer, LMarker, LPopup, LMap } from 'vue2-leaflet'
import vue2Dropzone from 'vue2-dropzone'

import { BACKEND_BASE_URL } from '../constants'

const { detect } = require('detect-browser')

export default {
  name: 'PlaceEdit',
  props: [
    'marker'
  ],
  components: {
    'v-map': LMap,
    'v-tilelayer': LTileLayer,
    'v-marker': LMarker,
    'v-popup': LPopup,
    'v-dropzone': vue2Dropzone
  },
  methods: {
    fetchAddress () {
      if (this.street && this.houseNumber && this.postCode && this.city) {
        this.$http.get('https://nominatim.openstreetmap.org/?&format=jsonv2&limit=1', {
          params: {
            format: 'jsonv2',
            street: this.houseNumber + ' ' + this.street,
            city: this.city,
            postalcode: this.postCode
          }
        }).then(response => {
          try {
            this.latitude = response.body[0].lat
            this.longitude = response.body[0].lon
            this.nominatimResponse = response.body[0]
          } catch (err) {
            this.latitude = null
            this.longitude = null
            this.nominatimResponse = null
          }
        })
      }
    },
    submitForm () {
      if (!this.latitude || !this.longitude) {
        this.$refs.addressErrorModalShow.show()
        return
      }

      let url

      if (this.formMode === 'NEW') {
        url = BACKEND_BASE_URL + '/v1/map/point'
      } else {
        url = BACKEND_BASE_URL + '/v1/map/point/' + this.$route.params.pid
      }

      this.$refs.finishModalShow.show()
      this.actualSubmitStatus = 'Senden...'

      const imageIdList = []
      const acceptedFiles = this.$refs.imageDropzone.dropzone.files
      for (const imageItem in acceptedFiles) {
        const imageObj = acceptedFiles[imageItem]
        if (imageObj.manuallyAdded) {
          imageIdList.push(this.manuallyAddedFiles[imageObj.name].pointImageId)
        } else {
          const response = JSON.parse(acceptedFiles[imageItem].xhr.response)
          imageIdList.push(response.pointImageId)
        }
      }

      const point = {
        emailAddressSubmitter: this.emailAddressSubmitter,
        emailAddress: this.emailAddress,
        title: this.title,
        street: this.street,
        houseNumber: this.houseNumber,
        postCode: this.postCode,
        city: this.city,
        offersConsulting: !!this.offersConsulting,
        offersABC: !!this.offersABC,
        offersMedia: !!this.offersMedia,
        offersOther: !!this.offersOther,
        shortDescription: this.shortDescription,
        description: this.description,
        telephoneNumber: this.telephoneNumber,
        website: this.website,
        latitude: this.latitude,
        longitude: this.longitude,
        images: imageIdList,
        alphaSiegel: this.alphaSiegel
      }

      for (const dayIdx in this.openingHoursDays) {
        const day = this.openingHoursDays[dayIdx]
        point['openingHours' + day.en + 'Open'] = this.openingHours[day.en].open
        point['openingHours' + day.en + 'Close'] = this.openingHours[day.en].close
      }

      this.$http.post(url, {
        point: point
      }).then((response) => {
        this.actualSubmitStatus = 'Erfolgreich!'
        this.submitSuccessful = true
      })

      return false
    },
    successDismiss () {
      this.$router.push('/')
    }
  },
  data () {
    return {
      browserDetect: detect(),

      loading: false,
      placeData: null,

      latitude: null,
      longitude: null,
      nominatimResponse: null,

      openingHoursDays: [
        {
          de: 'Montag',
          en: 'Monday'
        },
        {
          de: 'Dienstag',
          en: 'Tuesday'
        },
        {
          de: 'Mittwoch',
          en: 'Wednesday'
        },
        {
          de: 'Donnerstag',
          en: 'Thursday'
        },
        {
          de: 'Freitag',
          en: 'Friday'
        },
        {
          de: 'Samstag',
          en: 'Saturday'
        },
        {
          de: 'Sonntag',
          en: 'Sunday'
        }
      ],

      emailAddressSubmitter: null,
      emailAddress: null,
      title: null,
      street: null,
      houseNumber: null,
      postCode: null,
      city: null,
      offersConsulting: null,
      offersABC: null,
      offersMedia: null,
      offersOther: null,
      shortDescription: null,
      description: null,
      telephoneNumber: null,
      website: null,
      alphaSiegel: false,
      openingHours: {
        Monday: {
          open: null,
          close: null
        },
        Tuesday: {
          open: null,
          close: null
        },
        Wednesday: {
          open: null,
          close: null
        },
        Thursday: {
          open: null,
          close: null
        },
        Friday: {
          open: null,
          close: null
        },
        Saturday: {
          open: null,
          close: null
        },
        Sunday: {
          open: null,
          close: null
        }
      },

      actualSubmitStatus: 'UNKNOWN',
      submitSuccessful: false,

      mapAttribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      formMode: 'NEW',

      dropzoneOptions: {
        url: BACKEND_BASE_URL + '/v1/map/point/image',
        acceptedFiles: 'image/*',
        thumbnailMethod: 'contain',
        thumbnailWidth: null,
        addRemoveLinks: true
      },
      manuallyAddedFiles: {}
    }
  },
  created () {
    if (this.$route.params.pid && this.$route.params.rid) {
      this.formMode = 'MODIFY'
      this.$http.get(BACKEND_BASE_URL + '/v1/map/point/' + this.$route.params.pid + '/revision/' + this.$route.params.rid).then((response) => {
        this.emailAddress = response.body.emailAddress
        this.title = response.body.title
        this.street = response.body.street
        this.houseNumber = response.body.houseNumber
        this.postCode = response.body.postCode
        this.city = response.body.city
        this.offersConsulting = response.body.offersConsulting
        this.offersABC = response.body.offersABC
        this.offersMedia = response.body.offersMedia
        this.offersOther = response.body.offersOther
        this.shortDescription = response.body.shortDescription
        this.description = response.body.description
        this.telephoneNumber = response.body.telephoneNumber
        this.website = response.body.website
        this.alphaSiegel = response.body.alphaSiegel

        for (const dayIdx in this.openingHoursDays) {
          const day = this.openingHoursDays[dayIdx]
          if (response.body.openingHours[day.en].length > 0) {
            this.openingHours[day.en].open = response.body.openingHours[day.en][0].open
            this.openingHours[day.en].close = response.body.openingHours[day.en][0].close
          }
        }

        for (const imageNum in response.body.images) {
          const imageObj = response.body.images[imageNum]

          const fileName = 'Datei #' + imageNum
          const mockFile = { name: fileName, size: null, type: 'image/jpg' }

          this.manuallyAddedFiles[fileName] = { pointImageId: imageObj }

          // Call the default addedfile event handler
          this.$refs.imageDropzone.manuallyAddFile(mockFile, BACKEND_BASE_URL + '/v1/map/point/image/' + imageObj + '/full')
        }

        this.fetchAddress()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';
  @import "~leaflet/dist/leaflet.css";
  @import '~vue2-dropzone/dist/vue2Dropzone.min.css';

  .place-edit {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  #address-map {
    height: 250px;
    margin-bottom: 15px;
  }
</style>
