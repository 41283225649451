<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="$route.params.pid != null" class="hidden col-md-6 col-lg-4" id="activeMarkerInfoBox">
        <v-place :marker="$route.params.pid" v-on:close="disableMarker()"></v-place>
      </div>
      <div :class="{ 'col': true, 'd-md-block': $route.params.pid != null, 'd-none': $route.params.pid != null }" id="map-container">
        <div class="d-fixed" id="add-content">
          <b-button size="sm" to="/map/placeEdit">Einen neuen Ort hinzufügen</b-button>
        </div>
        <v-map id="map" ref="map" :zoom="mapZoom" :center="mapCenter">
          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" :attribution="mapAttribution"></v-tilelayer>
          <v-marker v-for="item in markers" :key="item.id" :lat-lng="item.position" :visible="item.visible" :draggable="item.draggable"
                    :icon="item.icon" v-on:click="activateMarker(item)">
          </v-marker>
        </v-map>
      </div>
    </div>
  </div>
</template>

<script>
import { LTileLayer, LMarker, LMap } from 'vue2-leaflet'
import Place from '../components/Place.vue'
import { BACKEND_BASE_URL } from '../constants'

var markers = []

export default {
  name: 'Map',
  components: {
    'v-map': LMap,
    'v-tilelayer': LTileLayer,
    'v-marker': LMarker,
    'v-place': Place
  },
  methods: {
    activateMarker (item) {
      this.$router.push('/map/place/' + item.id)
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
        this.mapCenter = item.position
      })
    },
    disableMarker () {
      this.$router.push('/map')
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
      })
    }
  },
  data () {
    return {
      markers: markers,
      activeMarker: null,
      mapAttribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapCenter: [52.54113457643645, 13.200073242187502],
      mapZoom: 13
    }
  },
  created () {
    this.$http.get(BACKEND_BASE_URL + '/v1/map/point/list').then((response) => {
      markers = []

      for (const rowItemKey in response.body) {
        const rowItem = response.body[rowItemKey]
        markers.push({
          id: rowItem.mapPoint,
          position:
            {
              lat: rowItem.latitude,
              lng: rowItem.longitude
            },
          draggable: false,
          visible: true
        })
      }

      this.markers = markers
    })
  }
}
</script>

<style scoped lang="scss">
  @import '../style/bootstrap-component-include';
  @import "~leaflet/dist/leaflet.css";

  #activeMarkerInfoBox {
    z-index: 2;
    padding: 0;
  }

  .row > div {
    height: calc(100vh - 56px);
  }

  #map-container {
    position: relative;
    margin: 0;
    #map {
      z-index: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    #add-content {
      z-index: 1;
      position: absolute;
      bottom: 5px;
      left: 5px;
    }
  }
</style>
