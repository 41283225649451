<template>
  <b-navbar toggleable="md" type="dark" class="navbar-dark sticky-top bg-dark">
    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
    <b-navbar-brand to="/" class="navbar-brand">
      <img src="../assets/images/logo-small-white.svg" id="navbar-logo">
      <span class="d-none d-sm2-inline">Alphabündnis <span class="d-none d-sm-inline">Berlin-</span>Spandau</span>
    </b-navbar-brand>

    <b-collapse is-nav id="nav_collapse">
      <b-navbar-nav>
        <b-nav-item to="/buendnis"><icon name="home" scale="1.2" class="symbol"></icon> Über uns</b-nav-item>
        <b-nav-item to="/map"><icon name="map" scale="1" class="symbol"></icon> AlphaMap</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/contact"><icon name="envelope" scale="1" class="symbol"></icon> Kontakt</b-nav-item>
        <b-nav-item to="/impress">Impressum</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navbar'
}
</script>

<style lang="scss" scoped>
  $grid-breakpoints: (
    xs: 0,
    sm2: 400px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
  );

  @import '../style/bootstrap-component-include';
  @import '~bootstrap/scss/bootstrap-grid';

  .symbol {
    margin-top: -5px;
    vertical-align: middle;
  }

  .sticky-top {
    z-index: 1;
  }

  #navbar-logo {
    margin-top: -5px;
    height: 30px;
  }
</style>
